import React from 'react'

import styles from './style.module.scss'
import { Link, StaticQuery, graphql } from 'gatsby'

import Image from 'gatsby-image'

import cn from 'classnames'

const Developers = ({
    limit,
    columns = 6,
    homepage,
    developerImage,
    developersPage
}) => (
    <StaticQuery
        query={graphql`
            {
                allWordpressWpDevelopers {
                    edges {
                        node {
                            name
                            slug
                            acf {
                                show_in_home_page
                                sort_order
                                logo_image {
                                    localFile {
                                        childImageSharp {
                                            fluid(maxWidth: 500) {
                                                ...GatsbyImageSharpFluid_withWebp
                                            }
                                        }
                                    }
                                }

                                developer_image {
                                    localFile {
                                        childImageSharp {
                                            fluid(maxWidth: 900) {
                                                ...GatsbyImageSharpFluid_withWebp
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={data => {
            let edges = data.allWordpressWpDevelopers.edges
            edges = edges.sort((e1, e2) => {
                if (e1.node.acf && e1.node.acf.sort_order) {
                    if (e2.node.acf && e2.node.acf.sort_order) {
                        return e1.node.acf.sort_order - e2.node.acf.sort_order
                    }
                    return -1
                }
                return 0
            })
            if (homepage) {
                edges = edges.filter(
                    edge => edge.node.acf && edge.node.acf.show_in_home_page
                )
            }
            if (limit) {
                edges = edges.slice(0, limit)
            }
            return (
                <div className={styles.developersContainer}>
                    <div className="wrapper">
                        {developersPage ? (
                            <h1 className={styles.header}>Developers</h1>
                        ) : (
                            <h3 className={styles.header}>Developers</h3>
                        )}

                        <div className="row">
                            {edges.map(
                                (edge, i) =>
                                    edge.node.acf.developer_image &&
                                    edge.node.acf.developer_image.localFile &&
                                    edge.node.acf.developer_image.localFile
                                        .childImageSharp && (
                                        <div
                                            key={i}
                                            className={
                                                homepage
                                                    ? 'col-xs-6 ' +
                                                      'col-sm-' +
                                                      12 / columns
                                                    : 'col-xs-12 ' +
                                                      'col-sm-' +
                                                      12 / columns
                                            }
                                        >
                                            <Link
                                                to={
                                                    '/developer/' +
                                                    edge.node.slug
                                                }
                                                title={edge.node.name}
                                                className={cn(
                                                    styles.developer,
                                                    {
                                                        [styles.homepage]: homepage
                                                    }
                                                )}
                                            >
                                                {developerImage ? (
                                                    <Image
                                                        fluid={
                                                            edge.node.acf
                                                                .developer_image
                                                                .localFile
                                                                .childImageSharp
                                                                .fluid
                                                        }
                                                        className={styles.image}
                                                        alt={edge.node.name}
                                                    />
                                                ) : (
                                                    <Image
                                                        fluid={
                                                            edge.node.acf
                                                                .logo_image
                                                                .localFile
                                                                .childImageSharp
                                                                .fluid
                                                        }
                                                        className={styles.image}
                                                        alt={edge.node.name}
                                                    />
                                                )}
                                                <h3
                                                    dangerouslySetInnerHTML={{
                                                        __html: edge.node.name
                                                    }}
                                                />
                                            </Link>
                                        </div>
                                    )
                            )}
                        </div>
                        {homepage && (
                            <Link
                                to="/developers"
                                className={styles.viewAll}
                                title="View all developers"
                            >
                                View all developers
                            </Link>
                        )}
                    </div>
                </div>
            )
        }}
    />
)

export default Developers
