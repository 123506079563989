import React from 'react'

import { graphql } from 'gatsby'

import SEO from '../components/seo'

import Layout from '../components/layout'

import Developers from '../components/developers'

import SeoSearch from '../components/seo-search'

import SearchBox from '../components/search-box'

export default ({ data }) => {
    let page = data.allWordpressPage.edges[0].node

    return (
        <Layout
            breadcrumb={[{ title: 'Home', link: '/' }, { title: 'Developers' }]}
            marketTicker
        >
            <SEO
                title={page.yoast_meta.yoast_wpseo_title}
                description={page.yoast_meta.yoast_wpseo_metadesc}
            />
            <SearchBox noNegativeMargin hideLinks />
            <Developers columns={3} developerImage developersPage />
            <SeoSearch data={data} />
        </Layout>
    )
}

export const query = graphql`
    {
        allWordpressWpAreas {
            edges {
                node {
                    name
                    slug
                    wordpress_id
                }
            }
        }
        allWordpressWpSeoSearch {
            edges {
                node {
                    title
                    slug
                    wordpress_id
                    seo_search_category
                    areas
                }
            }
        }

        allWordpressWpSeoSearchCategory {
            edges {
                node {
                    name
                    slug
                    wordpress_id
                }
            }
        }
        allWordpressPage(filter: { slug: { eq: "developers" } }) {
            edges {
                node {
                    yoast_meta {
                        yoast_wpseo_title
                        yoast_wpseo_metadesc
                    }
                }
            }
        }
    }
`
